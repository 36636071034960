const data = {
  pageLinks: [
    {
      title: 'Learn in Public (Shawn Wang)',
      link: 'https://www.swyx.io/writing/learn-in-public/',
      type: 'article'
    },
    {
      title: 'My magic response to "Hey, can I pick your brain?"',
      link: 'https://stackingthebricks.com/pick-your-brain/',
      type: 'article'
    },
    {
      title: 'How I am so productive',
      link: 'https://kentcdodds.com/blog/how-i-am-so-productive',
      type: 'article'
    },
    {
      title: 'Wes Bos on audience building',
      link: 'https://twitter.com/wesbos/status/1258785173098737666',
      type: 'thread'
    },
    {
      title: 'The Ultimate Guide to Writing Online',
      link: 'https://www.perell.com/blog/the-ultimate-guide-to-writing-online',
      type: 'article'
    },
    {
      title: `Don't End the Week with Nothing`,
      link: 'https://training.kalzumeus.com/newsletters/archive/do-not-end-the-week-with-nothing',
      type: 'article'
    },
    {
      title: 'Window-openers vs Door-knockers',
      link: 'https://email.aliabdaal.com/issues/window-openers-vs-door-knockers-202120',
      type: 'article'
    },
    {
      title: 'How to Maximize Opportunity and Success',
      link: 'https://www.youtube.com/watch?v=y1NKkWELlhQ',
      type: 'video'
    },
    {
      title: 'How to Convice Your Friend to Start a Blog',
      link: 'https://www.notoverthinking.com/episodes/how-to-convince-your-friend-to-start-a-blog',
      type: 'podcast'
    },
  ],
};

export default data